import { TableCell, Theme } from '@mui/material'
import { withStyles } from '@mui/styles'

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    fontSize: '13px',
    padding: '16px 16px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: 'auto',
    whiteSpace: 'nowrap',
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
    '&:first-of-type': {
      top: 0,
      left: 0,
      zIndex: 3,
      position: 'sticky',
    },
  },
  body: {
    fontSize: '12px',
    '&:first-of-type': {
      left: '0px',
      position: 'sticky',
      zIndex: 1,
      background: 'inherit',
      '& span': {
        lineHeight: '24px',
      },
    },
  },
}))(TableCell)

export default StyledTableCell
