import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RefreshButton from '../../components/RefreshButton'
import { Order, Tenant } from '../../types'
import { createTenant, editTenant, fetchTenants } from '../../api/API'
import { useDebounce } from 'use-debounce/lib'
import { EnhancedTableHead } from '../../components/EnchancedTable'
import TenantEditRow from './components/TenantEditRow'
import TenantCreator, { CreateTenantCmd } from './components/TenantCreator'
import { getErrorMessage } from '../../utils/AxiosUtils'

const columns = [
  { key: 'name', label: 'Name', width: '15%' },
  { key: 'internal', label: 'Is Patient21', width: '15%' },
  { key: 'deprecated', label: 'Active', width: '13%' },
]

const Tenants: React.FC = () => {
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingTenant, setLoadingTenant] = useState<{ [key: string]: boolean }>({})
  const [error, setError] = useState<string | null>(null)
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [count, setCount] = useState(0)
  const [showCreateTenant, setShowCreateTenant] = useState(false)
  const [creatingTenant, setCreatingTenant] = useState(false)
  const [creatingTenantError, setCreatingTenantError] = useState<string | null>(null)

  const [nameFilter, setNameFilter] = useState<string>('')
  const [nameFilterDebounced] = useDebounce(nameFilter, 750, { leading: false })

  const fetchTenantsCallback = useCallback(() => {
    if (fetchTenants) {
      setLoading(true)
      fetchTenants(page, rowsPerPage, 'name', nameFilterDebounced)
        .then((result) => {
          setTenants(result.content)
          setCount(result.totalElements)
          setError(null)
        })
        .catch((error) => setError(error.message))
        .finally(() => {
          setLoading(false)
        })
    }

    //we don't want to include api in the dependencies
    //eslint-disable-next-line
  }, [page, rowsPerPage, nameFilterDebounced])

  useEffect(fetchTenantsCallback, [fetchTenantsCallback])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleTenantEdit = (
    tenantId: string,
    name: string | null,
    internal: boolean | null,
    deprecated: boolean | null
  ) => {
    const tenant = tenants.filter((t) => t.referenceId === tenantId)[0]

    if (tenant != null && (name != null || internal != null || deprecated != null)) {
      setLoadingTenant({ ...loadingTenant, [tenantId]: true })

      editTenant(tenantId, name, internal, deprecated)
        .then((result) => {
          const newTenants = tenants.map((tenant) => (tenant.referenceId === tenantId ? result.data : tenant))
          setTenants(newTenants)
        })
        .finally(() => {
          setLoadingTenant({ ...loadingTenant, [tenantId]: false })
        })
    }
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  }

  const handleTenantCreateDiscard = () => {
    setCreatingTenantError(null)
    setShowCreateTenant(false)
  }

  const handleTenantCreate = (cmd: CreateTenantCmd) => {
    setCreatingTenant(true)
    createTenant(cmd.name, cmd.internal)
      .then(() => {
        setShowCreateTenant(false)
        fetchTenantsCallback()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        setCreatingTenantError(errorMessage)
      })
      .finally(() => {
        setCreatingTenant(false)
      })
  }

  const rows = useMemo(
    () =>
      tenants.map((tenant) => (
        <TenantEditRow
          key={`tenant${tenant.referenceId}`}
          tenant={tenant}
          loading={loadingTenant[tenant.referenceId]}
          saveEdit={handleTenantEdit}
        />
      )),
    //no need to include handleUserEdit as a dependency, just makes everything slow
    //eslint-disable-next-line
    [tenants, loadingTenant]
  )

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h4" color="primary" gutterBottom={false}>
            {'Tenants'}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label={'Filter by name'}
                variant="outlined"
                value={nameFilter}
                onChange={handleNameFilterChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
            <Grid container justifyContent="flex-end">
              <Button color="primary" size="large" onClick={() => setShowCreateTenant(true)}>
                <AddIcon fontSize="large" />
                {'Create'}
              </Button>
              <RefreshButton loading={loading} refresh={fetchTenantsCallback} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>Error fetching Users</AlertTitle>
              {error}
            </Alert>
          ) : (
            <Paper>
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={() => {}}
                    rowCount={tenants.length}
                    leadColspan={2}
                  />
                  <TableBody>
                    <colgroup>
                      {columns.map((column) => (
                        <col key={`colgroup_${column.key}`} width={column.width} />
                      ))}
                    </colgroup>
                    {rows}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <TenantCreator
        open={showCreateTenant}
        loading={creatingTenant}
        error={creatingTenantError}
        onCancel={handleTenantCreateDiscard}
        onCreate={handleTenantCreate}
      />
    </div>
  )
}

export default Tenants
