import React, { useCallback, useState, useEffect, useMemo } from 'react'

import { User, Order, UserChange } from '../../types'
import { fetchUserChanges } from '../../api/API'
import { useDebounce } from 'use-debounce/lib'
import UserChangeRow from './components/UserChangeRow'
import StyledTableCell from '../../components/StyledTableCell'
import RefreshButton from '../../components/RefreshButton'
import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

const columns = [
  { key: 'time', label: 'Time', align: 'left', width: 'small' },
  { key: 'modifiedBy', label: 'Modified By', align: 'left', width: 'small' },
  { key: 'affectedUser', label: 'Affected user', align: 'left', width: 'small' },
  { key: 'change', label: 'Change', align: 'right', width: 'medium' },
]

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <StyledTableCell key={headCell.key} sortDirection={orderBy === headCell.key ? order : false}>
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const UserChanges: React.FC = () => {
  const [users, setUsers] = useState<UserChange[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [nameFilter, setNameFilter] = useState<string>('')
  const [nameFilterDebounced] = useDebounce(nameFilter, 750, { leading: false })

  const [emailFilter, setEmailFilter] = useState<string>('')
  const [emailFilterDebounced] = useDebounce(emailFilter, 750, { leading: false })

  const fetchUsersCallback = useCallback(() => {
    if (fetchUserChanges) {
      setLoading(true)
      fetchUserChanges(page, rowsPerPage, 'name', nameFilterDebounced, emailFilterDebounced)
        .then((result) => {
          setUsers(result.data)
          setError(null)

          const newLoadingUser = {} as { [key: string]: boolean }
          result.data.forEach((user: User) => (newLoadingUser[user.referenceId] = false))
        })
        .catch((error) => setError(error.message))
        .finally(() => {
          setLoading(false)
        })
    }

    //we don't want to include api in the dependencies
    //eslint-disable-next-line
  }, [page, rowsPerPage, nameFilterDebounced, emailFilterDebounced])

  useEffect(fetchUsersCallback, [fetchUsersCallback])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  }

  const handleEmailFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(event.target.value)
  }

  const rows = useMemo(
    () => users.map((user) => <UserChangeRow {...user} />),
    //no need to include handleUserEdit as a dependency, just makes everything slow
    //eslint-disable-next-line
    [users]
  )

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h4" color="primary" gutterBottom={false}>
            {'User Changes'}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label={'Filter by name'}
                variant="outlined"
                value={nameFilter}
                onChange={handleNameFilterChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={'Filter by email'}
                variant="outlined"
                value={emailFilter}
                onChange={handleEmailFilterChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
            <Grid container justifyContent="flex-end">
              <RefreshButton loading={loading} refresh={fetchUsersCallback} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            Array.from(Array(6)).map((_, idx) => <Skeleton key={`skeleton-${idx}`} height="82px" animation="wave" />)
          ) : error ? (
            <Alert severity="error">
              <>
                <AlertTitle>Error fetching Users</AlertTitle>
                {error}
              </>
            </Alert>
          ) : (
            <Paper>
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={() => {}} rowCount={users.length} />
                  <TableBody>{rows}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[20, 40, 50]}
                component="div"
                count={
                  users.length < rowsPerPage ? page * rowsPerPage + users.length : page * rowsPerPage + 2 * rowsPerPage
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default UserChanges
