import React, { useState } from 'react'

import { StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import { Clinic, Practitioner, Role, RoleForClinics, Tenant, User } from '../../../types'
import TenantPicker from '../../../components/TenantPicker'
import TokenGenerator from './TokenGenerator'
import { useCredentials } from '@dentalux/security'
import LinkedPractitioner from './LinkedPractitioner'
import UserEnabled from '../../../components/EnabledSwtich'
import { createStyles, makeStyles } from '@mui/styles'
import { Avatar, Checkbox, CircularProgress, IconButton, Table, tableCellClasses, TableRow, Theme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import RolesForClinicsPicker from './RolesForClinicsPicker'
import { canGenerateTokens } from '../../../utils/AuthUtils'
import LinkedPractitionerEdit from './LinkedPractitionerEdit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.4),
      marginBottom: theme.spacing(0.7),
      marginTop: theme.spacing(0.7),
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      height: 24,
      width: 24,
    },
  })
)

interface Props {
  user: User
  roles: Role[]
  tenants: Tenant[]
  clinics: Clinic[]
  saveEdit: (
    userId: string,
    rolesForClinics: RoleForClinics[] | null,
    tenant: Tenant | null,
    disabled: boolean | null,
    practitioners: Practitioner[],
    lock: boolean
  ) => void
  loading: boolean
}

const findTenant = (tenants: Tenant[] | undefined, tenantName: string | null): Tenant | null => {
  if (tenants && tenantName) {
    return tenants.find((tenant) => tenant.name === tenantName) || null
  } else {
    return null
  }
}

const UserEditRow: React.FC<Props> = ({ user, roles, tenants, clinics, saveEdit, loading }) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [rolesForClinics, setRolesForClinics] = useState(user.roles)
  const [tenant, setTenant] = useState(findTenant(tenants, user.tenant))
  const [enabled, setEnabled] = useState(!user.disabled)
  const [lock, setLock] = useState(user.practitioners.some((pr) => pr.lockUser))
  const [practitioners, setPractitioners] = useState(user.practitioners)
  const { authentication } = useCredentials()

  const showImpersonation = canGenerateTokens(authentication.roles)

  const toggleEdit = () => {
    setEdit(!edit)
  }

  const onChangeLock = () => {
    setLock(!lock)
  }

  const onCancel = () => {
    setRolesForClinics(user.roles)
    setTenant(findTenant(tenants, user.tenant))
    setEnabled(!user.disabled)
    toggleEdit()
  }

  const onSave = () => {
    saveEdit(
      user.referenceId,
      rolesForClinics,
      tenant || null,
      user.disabled === enabled ? !enabled : null,
      practitioners,
      lock
    )
    toggleEdit()
  }

  return edit ? (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={`edit_row_${user.referenceId}`}>
      <StyledTableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={onSave} color="primary">
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <CheckIcon />}
        </IconButton>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </StyledTableCell>

      {showImpersonation && <StyledTableCell padding="checkbox" align="right"></StyledTableCell>}

      <StyledTableCell padding="checkbox" align="right">
        <Avatar alt={user.name} src={user.imgUrl as string} component="span" />
      </StyledTableCell>

      <StyledTableCell align="left">{user.name}</StyledTableCell>

      <StyledTableCell align="right">{user.email}</StyledTableCell>

      <StyledTableCell align="right">
        {tenants.length ? <TenantPicker value={tenant} options={tenants} onChange={setTenant} /> : user.tenant}
      </StyledTableCell>

      <StyledTableCell align="right">
        <div className={classes.cellContainer}>
          <LinkedPractitionerEdit practitioners={user.practitioners} onChange={setPractitioners} />
          <div>
            <LockIcon color="primary" />
            <Checkbox value={lock} onChange={onChangeLock} className={classes.checkbox} />
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell align="center">
        {<UserEnabled enabled={enabled} editable={true} onChange={setEnabled} />}
      </StyledTableCell>

      <StyledTableCell align="right">
        <RolesForClinicsPicker
          rolesForClinics={rolesForClinics}
          roleOptions={roles}
          clinicOptions={clinics}
          onChange={setRolesForClinics}
        />
      </StyledTableCell>
    </StyledTableRow>
  ) : (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={`view_row_${user.referenceId}`}>
      <StyledTableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={toggleEdit}>
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <EditIcon />}
        </IconButton>
      </StyledTableCell>

      {showImpersonation && (
        <StyledTableCell padding="checkbox" align="center">
          <TokenGenerator user={user} />
        </StyledTableCell>
      )}

      <StyledTableCell padding="checkbox" align="right">
        <Avatar alt={user.name} src={user.imgUrl as string} component="span" />
      </StyledTableCell>

      <StyledTableCell align="left">{user.name}</StyledTableCell>

      <StyledTableCell align="right">{user.email}</StyledTableCell>

      <StyledTableCell align="right">{user.tenant}</StyledTableCell>

      <StyledTableCell align="right">
        <LinkedPractitioner practitioners={user.practitioners} />
      </StyledTableCell>
      <StyledTableCell align="center">
        {<UserEnabled enabled={!user.disabled} editable={false} onChange={() => {}} />}
      </StyledTableCell>

      <StyledTableCell align="right">
        <Table
          size="small"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          {user.roles.map(({ role, clinics }) => (
            <TableRow
              key={`role_${role.referenceId}_clinics_${clinics.map((clinic) => clinic.referenceId).join('__')}`}
            >
              <StyledTableCell>{role.name}</StyledTableCell>
              <StyledTableCell align="right">{clinics.map((clinic) => clinic.name).join(', ')}</StyledTableCell>
            </TableRow>
          ))}
        </Table>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default UserEditRow
