import React, { useEffect, useState } from 'react'

import { Autocomplete, Checkbox, FormControl, ListItem, ListItemText, TextField } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import { Practitioner } from '../../../types'
import { fetchPractitioners } from '../../../api/API'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  practitioners?: Practitioner[]
  onChange: (val: Practitioner[]) => void
}

const LinkedPractitionerEdit: React.FC<Props> = ({ practitioners = [], onChange }) => {
  const [values, setValues] = useState(practitioners)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly Practitioner[]>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(values ? values : [])
      return undefined
    }

    fetchPractitioners(0, 10, inputValue).then((data: Practitioner[]) => {
      if (active) {
        setOptions(data)
      }
    })

    return () => {
      active = false
    }
  }, [values, inputValue])

  const handleChange = (event: React.SyntheticEvent, value: Practitioner[], reason: string) => {
    setOptions(value ? [...value, ...options] : options)
    setValues(value)
    if (reason === 'removeOption' && !open) {
      onChange(value)
    }
  }

  const handleOnOpen = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    onChange(values)
    setOpen(false)
  }

  const getNoOptionsLabel = () => {
    return inputValue ? 'No practitioners found' : 'Please type a practitioner name'
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        multiple
        value={values}
        options={options}
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        includeInputInList
        disableClearable
        disableCloseOnSelect
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        onChange={handleChange}
        onClose={handleOnClose}
        onOpen={handleOnOpen}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={`option_${option.referenceId}`}>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <ListItemText primary={option.name} secondary={option.clinic.name} />
          </ListItem>
        )}
        isOptionEqualToValue={(option, value) => value.referenceId === option.referenceId}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        noOptionsText={getNoOptionsLabel()}
      />
    </FormControl>
  )
}

export default LinkedPractitionerEdit
