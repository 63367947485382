import React from 'react'

import { UseQueryResult, useQuery } from 'react-query'

import { Order, Role, Level, Action, DataPermission } from '../types'
import RefreshButton from '../components/RefreshButton'
import { fetchRoles } from '../api/API'
import StyledChip from '../components/StyledChip'
import { StyledTableCell } from '../components/EnchancedTable'
import { createStyles, makeStyles, withStyles } from '@mui/styles'
import {
  Alert,
  AlertTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import CreateIcon from '@mui/icons-material/Create'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
)

const columns = [
  { key: 'name', label: 'Name' },
  { key: 'all', label: 'All' },
  { key: 'own', label: 'Own' },
  { key: 'sameClinic', label: 'Same Clinic' },
  { key: 'sameLevel', label: 'Same Level' },
  { key: 'sameTenant', label: 'Same Tenant' },
]

const actionToIcon = {
  [Action.READ]: <SearchIcon />,
  [Action.WRITE]: <CreateIcon />,
  [Action.READ_WRITE]: <DoneAllIcon />,
}

interface EnhancedTableProps {
  classes?: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <StyledTableCell
            key={headCell.key}
            align={headCell.key === 'name' ? 'left' : 'right'}
            padding="normal"
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {headCell.label}
            <IconButton
              size="small"
              color={orderBy === headCell.key ? 'primary' : 'default'}
              aria-label={`Sort by ${headCell.label}`}
            >
              {orderBy === headCell.key && order === 'desc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const getPermissionsByLevel = (permissions: DataPermission[]): Record<Level, DataPermission[]> => {
  return permissions.reduce(
    (permissionsByLevel, permission) => {
      const previousValues = permissionsByLevel[permission.level] || []

      return {
        ...permissionsByLevel,
        [permission.level]: [...previousValues, permission],
      }
    },
    {
      ALL: [],
      OWN: [],
      SAME_LEVEL: [],
      SAME_CLINIC: [],
      TENANT_ADMIN: [],
    }
  )
}

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: 65,
  },
}))(TableRow)

const order = 'asc'
const orderBy = 'name'
const selected = []
const page = 0
const rowsPerPage = 100

const Roles: React.FC = () => {
  const classes = useStyles()

  const roles: UseQueryResult<Role[]> = useQuery(['roles'], () => fetchRoles(0, 100, 'name'))

  const renderDataPermission = (section: string, dataPermission: DataPermission) => (
    <StyledChip
      className={classes.chip}
      key={`${section}_${dataPermission.permission.action}_${dataPermission.permission.resource}`}
      color="primary"
      icon={actionToIcon[dataPermission.permission.action]}
      label={`${dataPermission.permission.action} ${dataPermission.permission.resource}`}
      deleteIcon={<CloseIcon />}
      onDelete={() => {}}
      variant="outlined"
    />
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary" gutterBottom={false}>
          Data permissions
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
          <Grid container justifyContent="flex-end">
            <RefreshButton loading={roles.isFetching} refresh={roles.refetch} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {roles.error ? (
          <Alert severity="error">
            <>
              <AlertTitle>Error fetching Users</AlertTitle>
              {roles.error}
            </>
          </Alert>
        ) : (
          <Paper>
            <TableContainer>
              <Table size="medium">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={() => {}}
                  rowCount={roles.data?.length || 0}
                />
                <TableBody>
                  {roles.data?.map((role) => {
                    const { ALL, OWN, SAME_LEVEL, SAME_CLINIC, TENANT_ADMIN } = getPermissionsByLevel(
                      role.dataPermissions
                    )

                    return (
                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={role.referenceId}>
                        <StyledTableCell align="left">{role.name}</StyledTableCell>

                        <StyledTableCell align="right">
                          {ALL.map((permission) => renderDataPermission('ALL', permission))}
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {OWN.map((permission) => renderDataPermission('OWN', permission))}
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {SAME_CLINIC.map((permission) => renderDataPermission('SAME_CLINIC', permission))}
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {SAME_LEVEL.map((permission) => renderDataPermission('SAME_LEVEL', permission))}
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          {TENANT_ADMIN.map((permission) => renderDataPermission('SAME_TENANT', permission))}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 200, 500]}
              component="div"
              count={roles.data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

export default Roles
