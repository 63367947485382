import React, { useCallback, useState, useEffect, useMemo } from 'react'

import { User, Order, Clinic, Role, SystemUser, ApiKeyFull, ApiKey, Tenant } from '../../types'
import {
  createApiKey,
  createSystemUser,
  editApiKey,
  editSystemUser,
  fetchClinics,
  fetchRoles,
  fetchSystemUsers,
  fetchTenants,
} from '../../api/API'
import { useDebounce } from 'use-debounce/lib'
import AddIcon from '@mui/icons-material/Add'
import RefreshButton from '../../components/RefreshButton'
import ClinicPicker from '../../components/ClinicPicker'
import { UseQueryResult, useQuery } from 'react-query'
import RolesPicker from '../../components/RolesPicker'
import SystemUserCreator, { CreateSystemUserCmd } from './components/SystemUserCreator'
import { getErrorMessage } from '../../utils/AxiosUtils'
import SystemUserRow from './components/SystemUserRow'
import { containsSame } from '../../utils/CollectionUtils'
import { EnhancedTableHead } from '../../components/EnchancedTable'
import ApiKeyCreateResult from './components/ApiKeyCreateResult'
import ApiKeyList from './components/ApiKeyList'
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

const columns = [
  { key: 'name', label: 'Name', align: 'left', width: '15%' },
  { key: 'role', label: 'Role', align: 'left', width: '5%' },
  { key: 'tenant', label: 'Tenant', align: 'left', width: '5%' },
  { key: 'clinics', label: 'Clinics', align: 'left', width: '40%' },
  { key: 'apiKeys', label: 'API Keys', align: 'left', width: '10%' },
]

const SystemUsers: React.FC = () => {
  const [users, setUsers] = useState<SystemUser[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingUser, setLoadingUser] = useState<{ [key: string]: boolean }>({})
  const [error, setError] = useState<string | null>(null)
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)

  const [showCreateUser, setShowCreateUser] = useState(false)
  const [creatingUser, setCreatingUser] = useState(false)
  const [creatingUserError, setCreatingUserError] = useState<string | null>(null)

  const [showCreateApiKey, setShowCreateApiKey] = useState(false)
  const [createdApiKey, setCreatedApiKey] = useState<ApiKeyFull | null>(null)

  const [showApiKeyList, setShowApiKeyList] = useState(false)
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([])

  const [nameFilter, setNameFilter] = useState<string>('')
  const [nameFilterDebounced] = useDebounce(nameFilter, 750, { leading: false })

  const [clinicFilter, setClinicFilter] = useState<Clinic[]>([])
  const [clinicFilterDebounced] = useDebounce(clinicFilter, 750, { leading: false })

  const [roleFilter, setRoleFilter] = useState<Role[]>([])
  const [roleFilterDebounced] = useDebounce(roleFilter, 750, { leading: false })

  const fetchUsersCallback = useCallback(() => {
    if (fetchSystemUsers) {
      setLoading(true)
      fetchSystemUsers(page, rowsPerPage, 'name', nameFilterDebounced, clinicFilterDebounced, roleFilterDebounced)
        .then((result) => {
          setUsers(result.data)
          setError(null)

          const newLoadingUser = {} as { [key: string]: boolean }
          result.data.forEach((user: User) => (newLoadingUser[user.referenceId] = false))
        })
        .catch((error) => setError(error.message))
        .finally(() => {
          setLoading(false)
        })
    }

    //we don't want to include api in the dependencies
    //eslint-disable-next-line
  }, [page, rowsPerPage, nameFilterDebounced, clinicFilterDebounced, roleFilterDebounced])

  useEffect(fetchUsersCallback, [fetchUsersCallback])

  const roles: UseQueryResult<Role[]> = useQuery(['roles'], () => fetchRoles(0, 100, 'name', ['SYSTEM,ANY']))

  const clinics: UseQueryResult<Clinic[]> = useQuery(['clinics'], () => fetchClinics())

  const tenants: UseQueryResult<Tenant[]> = useQuery(['tenants'], () => fetchTenants(0, 100, 'name', ''), {
    select: (data) => data.content,
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  }

  const handleUserCreateDiscard = () => {
    setCreatingUserError(null)
    setShowCreateUser(false)
  }

  const handleUserCreate = (cmd: CreateSystemUserCmd) => {
    setCreatingUser(true)
    createSystemUser(cmd.name, cmd.tenantReferenceId)
      .then(() => {
        setShowCreateUser(false)
        fetchUsersCallback()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        setCreatingUserError(errorMessage)
      })
      .finally(() => {
        setCreatingUser(false)
      })
  }

  const handleUserEdit = (
    userId: string,
    roleId: string | null,
    tenantId: string | null,
    clinicIds: string[] | null
  ) => {
    const user = users.filter((u) => u.referenceId === userId)[0]

    if (
      (roleId || tenantId || clinicIds) &&
      (user.role === null ||
        user.role.referenceId !== roleId ||
        user.tenant === null ||
        user.tenant.referenceId !== tenantId ||
        !containsSame(
          user.clinics.map((clinic) => clinic.referenceId),
          clinicIds || []
        )) &&
      editSystemUser
    ) {
      setLoadingUser({ ...loadingUser, [userId]: true })
      editSystemUser(userId, roleId, tenantId, clinicIds)
        .then((result) => {
          const newUsers = users.map((user) => (user.referenceId === userId ? result.data : user))
          setUsers(newUsers)
        })
        .finally(() => {
          setLoadingUser({ ...loadingUser, [userId]: false })
        })
    }
  }

  const handleApiKeyCreate = (userId: string) => {
    createApiKey(userId)
      .then((res) => {
        setCreatedApiKey(res.data)
        setShowCreateApiKey(true)
        fetchUsersCallback()
      })
      .catch((error) => {
        setShowCreateApiKey(true)
      })
  }

  const handleApiKeyResultClose = () => {
    setShowCreateApiKey(false)
    setCreatedApiKey(null)
  }

  const handleShowApiKeyList = (apiKeys: ApiKey[]) => {
    setApiKeys(apiKeys)
    setShowApiKeyList(true)
  }

  const handleApiKeyListClose = () => {
    setShowApiKeyList(false)
    setApiKeys([])
  }

  const handleApiKeyEdit = (apiKeyId: string, enabled: boolean) => {
    editApiKey(apiKeyId, enabled).then((res) => {
      const apiKeysUpdated = [...apiKeys]
      const idx = apiKeysUpdated.findIndex((apiKey) => apiKey.referenceId === res.data.referenceId)
      apiKeysUpdated[idx] = res.data
      setApiKeys(apiKeysUpdated)
      fetchUsersCallback()
    })
  }

  const rows = useMemo(
    () =>
      users.map((user) => (
        <SystemUserRow
          user={user}
          roles={roles.data || []}
          clinics={clinics.data || []}
          tenants={tenants.data || []}
          loading={loadingUser[user.referenceId]}
          saveEdit={handleUserEdit}
          createApiKey={handleApiKeyCreate}
          showApiKeys={handleShowApiKeyList}
        />
      )),
    //no need to include handleUserEdit as a dependency, just makes everything slow
    //eslint-disable-next-line
    [users, roles, clinics, tenants.data, loadingUser]
  )

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="h4" color="primary" gutterBottom={false}>
            {'System Users'}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label={'Filter by name'}
                variant="outlined"
                value={nameFilter}
                onChange={handleNameFilterChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <ClinicPicker
                label={'Filter by clinic'}
                value={clinicFilter}
                options={clinics.data || []}
                onChange={setClinicFilter}
              />
            </Grid>
            <Grid item xs={4}>
              <RolesPicker
                label={'Filter by role'}
                value={roleFilter}
                options={roles.data || []}
                onChange={setRoleFilter}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
            <Grid container justifyContent="flex-end">
              <Button color="primary" size="large" onClick={() => setShowCreateUser(true)}>
                <AddIcon fontSize="large" />
                {'Create'}
              </Button>
              <RefreshButton loading={loading} refresh={fetchUsersCallback} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            Array.from(Array(6)).map((_, idx) => <Skeleton key={`skeleton-${idx}`} height="82px" animation="wave" />)
          ) : error ? (
            <Alert severity="error">
              <>
                <AlertTitle>Error fetching Users</AlertTitle>
                {error}
              </>
            </Alert>
          ) : (
            <Paper>
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={() => {}}
                    rowCount={users.length}
                  />
                  <TableBody>
                    <colgroup>
                      <col width="3%" />
                      {columns.map((column) => (
                        <col key={`colgroup_${column.key}`} width={column.width} />
                      ))}
                    </colgroup>
                    {rows}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={
                  users.length < rowsPerPage ? page * rowsPerPage + users.length : page * rowsPerPage + 2 * rowsPerPage
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <SystemUserCreator
        open={showCreateUser}
        loading={creatingUser}
        error={creatingUserError}
        onCancel={handleUserCreateDiscard}
        onCreate={handleUserCreate}
        tenants={tenants.data || []}
        inTenant={null}
      />
      <ApiKeyCreateResult
        open={showCreateApiKey}
        apiKey={createdApiKey}
        onCancel={handleApiKeyResultClose}
        error={null}
      />
      <ApiKeyList
        open={showApiKeyList}
        apiKeys={apiKeys}
        onCancel={handleApiKeyListClose}
        onToggleEnable={handleApiKeyEdit}
      />
    </div>
  )
}

export default SystemUsers
