import React from 'react'

import { Switch } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'

interface Props {
  enabled: boolean
  editable: boolean
  onChange: (state: boolean) => void
}

const EnabledSwtich: React.FC<Props> = ({ enabled, editable: edit, onChange }) => {
  return (
    <div>
      {edit ? (
        <Switch
          checked={enabled}
          onChange={() => onChange(!enabled)}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
        />
      ) : enabled ? (
        <CheckCircleOutlineIcon />
      ) : (
        <BlockIcon />
      )}
    </div>
  )
}

export default EnabledSwtich
