import { isAndroid, isIOS } from 'react-device-detect'
import { ApplicationPermission } from './types'

export interface Formatting {
  prefix: string
  suffix: string
  decimalScale: number
  fixedDecimalScale: boolean
  multiplier: number
}

const isProduction = window.location.hostname.startsWith('hades')

export const formats: { [key: string]: Formatting } = {
  euro: {
    prefix: '€',
    suffix: '',
    decimalScale: 2,
    fixedDecimalScale: true,
    multiplier: 1,
  },
  number: {
    prefix: '',
    suffix: '',
    decimalScale: 0,
    fixedDecimalScale: true,
    multiplier: 1,
  },
  fraction: {
    prefix: '',
    suffix: '',
    decimalScale: 2,
    fixedDecimalScale: true,
    multiplier: 1,
  },
  hours: {
    prefix: '',
    suffix: 'h',
    decimalScale: 2,
    fixedDecimalScale: true,
    multiplier: 1,
  },
  minutes: {
    prefix: '',
    suffix: ' min',
    decimalScale: 0,
    fixedDecimalScale: true,
    multiplier: 1,
  },
  percentage: {
    prefix: '',
    suffix: '%',
    decimalScale: 1,
    fixedDecimalScale: true,
    multiplier: 100,
  },
}

const canViewCalmaster = ({ application }: ApplicationPermission) => application === 'CALMASTER'
const canViewInsight = ({ application }: ApplicationPermission) => application === 'INSIGHT'
const canViewHappy = ({ application }: ApplicationPermission) => application === 'HAPPY'
const canViewAna = ({ application }: ApplicationPermission) => application === 'ANA'
const canViewJarvis = ({ view }: ApplicationPermission) => view === 'JARVIS'
const canViewShiftPlanner = ({ application }: ApplicationPermission) => application === 'SHIFT_PLANNER'
const canViewTodoList = ({ application }: ApplicationPermission) => application === 'TODO_LIST'
const canViewClaire = ({ application }: ApplicationPermission) => application === 'CLAIRE'

export const applicationsSupportingImpersonation = [
  {
    name: 'Calmaster',
    url: isProduction ? 'https://calmaster.dental21.de' : 'https://staging-calmaster.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewCalmaster),
  },
  {
    name: 'Insight',
    url: isProduction ? 'https://insight.dental21.de' : 'https://staging-insight.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewInsight),
  },
  {
    name: 'Happy',
    url: isProduction ? 'https://happy-backoffice.dental21.de' : 'https://staging-happy-backoffice.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewHappy),
  },
  {
    name: 'Ana',
    url: isProduction ? 'https://ana-backoffice.dental21.de' : 'https://staging-ana-backoffice.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewAna),
  },
  {
    name: 'Jarvis',
    url: isProduction ? 'https://jarvis.dental21.de' : 'https://staging-jarvis.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewJarvis),
  },
  {
    name: 'Shift Planner',
    url: isProduction ? 'https://shift-planning.dental21.de' : 'https://staging-shift-planning.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewShiftPlanner),
  },
  {
    name: 'Todo List',
    url: isProduction ? 'https://to-do-list.dental21.de' : 'https://staging-to-do-list.dental21.de',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewTodoList),
  },
  {
    name: 'Claire',
    url: isProduction ? '' : 'https://claire.center/pre-prod-p21/impersonate',
    haveAccess: (permissions: ApplicationPermission[]): boolean => permissions.some(canViewClaire),
  },
]

export const protocol = isIOS ? 'https' : isAndroid ? 'dental21' : null

export const my21ImpersonationUrl = (token: string) =>
  isProduction
    ? `${protocol}://my21.dental21.de/impersonation/${token}`
    : `${protocol}://staging-my21.dental21.de/impersonation/${token}`
