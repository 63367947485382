import React, { useMemo } from 'react'

import { IconButton, Table, tableCellClasses, TableRow } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import { Clinic, Role, RoleForClinics } from '../../../types'
import { StyledTableCell } from '../../../components/EnchancedTable'
import RolePicker from '../../../components/RolePicker'
import ClinicPicker from '../../../components/ClinicPicker'

const SELECT_ROLE = {
  name: 'Select role',
  referenceId: '',
  applicationPermissions: [],
  dataPermissions: [],
}

export interface Props {
  rolesForClinics: RoleForClinics[]
  roleOptions: Role[]
  clinicOptions: Clinic[]
  onChange: (rolesForClinics: RoleForClinics[]) => void
}

const RolesForClinicsPicker: React.FC<Props> = ({ rolesForClinics, roleOptions, clinicOptions, onChange }) => {
  const clinicOptionsSorted = useMemo(
    () => clinicOptions.sort((clinicA, clinicB) => clinicA.name.localeCompare(clinicB.name)),
    [clinicOptions]
  )

  const onChangeRole = (oldRole: Role, newRoleId: string) => {
    const newRole = roleOptions.find((role) => role.referenceId === newRoleId)
    if (newRole) {
      const rolesForClinicsChanged = [...rolesForClinics]
      const idx = rolesForClinicsChanged.findIndex(({ role }) => role === oldRole)
      if (idx > -1) {
        rolesForClinicsChanged[idx] = { ...rolesForClinicsChanged[idx], role: newRole }
        onChange(rolesForClinicsChanged)
      }
    }
  }

  const onChangeClinics = (forRole: Role, newClinics: Clinic[]) => {
    const rolesForClinicsChanged = [...rolesForClinics]
    const idx = rolesForClinicsChanged.findIndex(({ role }) => role === forRole)
    if (idx > -1) {
      rolesForClinicsChanged[idx] = { ...rolesForClinicsChanged[idx], clinics: newClinics }
      onChange(rolesForClinicsChanged)
    }
  }

  const onRemoveRole = (toRemove: Role) => {
    onChange(rolesForClinics.filter(({ role }) => role !== toRemove))
  }

  const onAddRole = () => {
    onChange([...rolesForClinics, { role: SELECT_ROLE, clinics: [] }])
  }

  const handleClinicsChange = (role: Role) => (newClinics: Clinic[]) => onChangeClinics(role, newClinics)

  return (
    <Table
      size="small"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none',
        },
      }}
    >
      {!rolesForClinics.length && (
        <TableRow key={`role_for_clinics_empty`}>
          <StyledTableCell>
            <IconButton onClick={onAddRole}>
              <AddIcon />
            </IconButton>
          </StyledTableCell>
        </TableRow>
      )}
      {rolesForClinics.map(({ role, clinics }, idx) => (
        <TableRow key={`role_${role.referenceId}_clinics_${clinics.map((clinic) => clinic.referenceId).join('__')}`}>
          <StyledTableCell>
            <RolePicker value={role} options={roleOptions} onChange={(roleId) => onChangeRole(role, roleId)} />
          </StyledTableCell>
          <StyledTableCell align="right">
            <ClinicPicker value={clinics} options={clinicOptionsSorted} onChange={handleClinicsChange(role)} />
          </StyledTableCell>
          <StyledTableCell>
            <IconButton onClick={() => onRemoveRole(role)}>
              <RemoveIcon />
            </IconButton>
            {idx === rolesForClinics.length - 1 && (
              <IconButton>
                <AddIcon onClick={onAddRole} />
              </IconButton>
            )}
          </StyledTableCell>
        </TableRow>
      ))}
    </Table>
  )
}

export default RolesForClinicsPicker
