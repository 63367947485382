import React from 'react'

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

interface Props {
  label?: string
  value: boolean | null
  onChange: (val: boolean | null) => void
  disabled?: boolean
}

const ActivePicker: React.FC<Props> = ({ label, value, onChange, disabled = false }) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const rawVal = event.target.value
    const newVal = rawVal === 'null' ? null : rawVal === 'true'
    onChange(newVal)
  }
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value + ''} label={label} onChange={handleChange} disabled={disabled}>
        <MenuItem value="null">All</MenuItem>
        <MenuItem value="false">Disabled</MenuItem>
        <MenuItem value="true">Enabled</MenuItem>
      </Select>
    </FormControl>
  )
}

export default ActivePicker
