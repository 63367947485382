import React, { useCallback, useState, useEffect, useMemo } from 'react'

import { User, Order, Role, Clinic, RoleForClinics, Page, Tenant, Practitioner } from '../../types'
import RefreshButton from '../../components/RefreshButton'
import UserEditRow from './components/UserEditRow'
import { EnhancedTableHead } from '../../components/EnchancedTable'
import UserCreator, { CreateUserCmd } from './components/UserCreator'
import { getErrorMessage } from '../../utils/AxiosUtils'
import { useCredentials } from '@dentalux/security/lib'
import { createUser, editUser, fetchUsers, fetchRoles, fetchClinics, fetchTenants } from '../../api/API'
import { useQuery, UseQueryResult } from 'react-query'
import ClinicPicker from '../../components/ClinicPicker'
import { useDebounce } from 'use-debounce/lib'
import RolesPicker from '../../components/RolesPicker'

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ActivePicker from '../../components/ActivePicker'
import { canGenerateTokens } from '../../utils/AuthUtils'

const getRolesToClinicsMap = (rolesForClinics: RoleForClinics[]): { [key: string]: string[] } => {
  const result = {} as { [key: string]: string[] }

  rolesForClinics.forEach(
    ({ role, clinics }) => (result[role.referenceId] = clinics.map((clinic) => clinic.referenceId))
  )

  return result
}

const columns = [
  { key: 'name', label: 'Name', width: '15%' },
  { key: 'email', label: 'Email', width: '15%' },
  { key: 'tenant', label: 'Tenant', width: '13%' },
  { key: 'practitioner', label: 'Associated with', width: '5%' },
  { key: 'enabled', label: 'Enabled', width: '5%' },
  { key: 'roles', label: 'Role And Clinics', width: '45%' },
]

const Users: React.FC = () => {
  const auth = useCredentials()
  const [users, setUsers] = useState<Page<User> | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingUser, setLoadingUser] = useState<{ [key: string]: boolean }>({})
  const [error, setError] = useState<string | null>(null)
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<string>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [creatingUser, setCreatingUser] = useState(false)
  const [creatingUserError, setCreatingUserError] = useState<string | null>(null)

  const [clinicFilter, setClinicFilter] = useState<Clinic[]>([])
  const [clinicFilterDebounced] = useDebounce(clinicFilter, 750, { leading: false })

  const [nameFilter, setNameFilter] = useState<string>('')
  const [nameFilterDebounced] = useDebounce(nameFilter, 750, { leading: false })

  const [emailFilter, setEmailFilter] = useState<string>('')
  const [emailFilterDebounced] = useDebounce(emailFilter, 750, { leading: false })

  const [roleFilter, setRoleFilter] = useState<Role[]>([])
  const [roleFilterDebounced] = useDebounce(roleFilter, 750, { leading: false })

  const [activeFilter, setActiveFilter] = useState<boolean | null>(true)

  const fetchUsersCallback = useCallback(() => {
    const disabledFilter = activeFilter === null ? undefined : !activeFilter
    setLoading(true)
    fetchUsers(
      page,
      rowsPerPage,
      'name',
      nameFilterDebounced,
      emailFilterDebounced,
      clinicFilterDebounced,
      roleFilterDebounced,
      disabledFilter
    )
      .then((result) => {
        setUsers(result.data)
        setError(null)
      })
      .catch((error) => setError(error.message))
      .finally(() => {
        setLoading(false)
      })

    //we don't want to include api in the dependencies
    //eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    clinicFilterDebounced,
    nameFilterDebounced,
    emailFilterDebounced,
    roleFilterDebounced,
    activeFilter,
  ])

  const roles: UseQueryResult<Role[]> = useQuery(['roles'], () => fetchRoles(0, 100, 'name'))

  const tenants: UseQueryResult<Tenant[]> = useQuery(['tenants'], () => fetchTenants(0, 100, 'name', ''), {
    select: (data) => data.content,
  })

  const clinics: UseQueryResult<Clinic[]> = useQuery(['clinics'], () => fetchClinics())

  useEffect(fetchUsersCallback, [fetchUsersCallback])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleUserEdit = (
    userId: string,
    rolesForClinics: RoleForClinics[] | null,
    tenant: Tenant | null,
    disabled: boolean | null,
    practitioners: Practitioner[] | null,
    lock: boolean | null
  ) => {
    const user = users?.content.filter((u) => u.referenceId === userId)[0]
    if (user != null) {
      if (
        (rolesForClinics || tenant || practitioners || lock != null || disabled !== null) &&
        (user.tenant !== tenant || disabled !== user.disabled || practitioners !== user.practitioners) &&
        editUser
      ) {
        setLoadingUser({ ...loadingUser, [userId]: true })

        const rolesToClinicsMap = rolesForClinics ? getRolesToClinicsMap(rolesForClinics) : null
        const practitionerRefIds = practitioners?.map((pr) => pr.referenceId) || null

        editUser(userId, rolesToClinicsMap, tenant?.name || null, disabled, practitionerRefIds, lock)
          .then((result) => {
            const newUsersContent = users?.content.map((user) => (user.referenceId === userId ? result.data : user))
            const newUsers = { ...users, content: newUsersContent } as Page<User>
            setUsers(newUsers)
          })
          .finally(() => {
            setLoadingUser({ ...loadingUser, [userId]: false })
          })
      }
    }
  }

  const handleUserCreateDiscard = () => {
    setCreatingUserError(null)
    setShowCreateUser(false)
  }

  const handleUserCreate = (cmd: CreateUserCmd) => {
    setCreatingUser(true)
    createUser(cmd.name, cmd.email, cmd.authenticationType, cmd.tenant?.name || null, cmd.password)
      .then(() => {
        setShowCreateUser(false)
        fetchUsersCallback()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        setCreatingUserError(errorMessage)
      })
      .finally(() => {
        setCreatingUser(false)
      })
  }

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value)
  }

  const handleEmailFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(event.target.value)
  }

  const showImpersonation = canGenerateTokens(auth.authentication.roles)

  const rows = useMemo(
    () =>
      users !== null && tenants.data != null ? (
        users?.content.map((user) => (
          <UserEditRow
            key={`user_${user.referenceId}`}
            user={user}
            loading={loadingUser[user.referenceId]}
            roles={roles.data || []}
            tenants={tenants.data || []}
            clinics={clinics.data || []}
            saveEdit={handleUserEdit}
          />
        ))
      ) : (
        <div></div>
      ),
    //no need to include handleUserEdit as a dependency, just makes everything slow
    //eslint-disable-next-line
    [users, roles.data, clinics.data, tenants.data, loadingUser]
  )

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Typography variant="h4" color="primary" gutterBottom={false}>
            {'Users'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={1.2}>
            <Grid item xs={2}>
              <TextField
                label={'Filter by name'}
                variant="outlined"
                value={nameFilter}
                onChange={handleNameFilterChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={'Filter by email'}
                variant="outlined"
                value={emailFilter}
                onChange={handleEmailFilterChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <ClinicPicker
                label={'Filter by clinic'}
                value={clinicFilter}
                options={clinics.data || []}
                onChange={setClinicFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <RolesPicker
                label={'Filter by role'}
                value={roleFilter}
                options={roles.data || []}
                onChange={setRoleFilter}
              />
            </Grid>
            <Grid item xs={2}>
              <ActivePicker label="User status" value={activeFilter} onChange={setActiveFilter} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {useMediaQuery((theme: Theme) => theme.breakpoints.up('sm')) && (
            <Grid container justifyContent="flex-end">
              <Button color="primary" size="large" onClick={() => setShowCreateUser(true)}>
                <AddIcon fontSize="large" />
                {'Create'}
              </Button>
              <RefreshButton loading={loading} refresh={fetchUsersCallback} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>Error fetching Users</AlertTitle>
              {error}
            </Alert>
          ) : users != null ? (
            <Paper>
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={() => {}}
                    rowCount={users?.content.length || 0}
                    leadColspan={showImpersonation ? 3 : 2}
                  />
                  <TableBody>
                    <colgroup>
                      <col width="3%" />
                      <col width="2%" />
                      {columns.map((column) => (
                        <col key={`colgroup_${column.key}`} width={column.width} />
                      ))}
                    </colgroup>
                    {rows}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={users?.totalElements || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
      <UserCreator
        open={showCreateUser}
        inTenant={auth.authentication?.tennant || null}
        tenants={tenants.data || []}
        loading={creatingUser}
        error={creatingUserError}
        onCancel={handleUserCreateDiscard}
        onCreate={handleUserCreate}
      />
    </div>
  )
}

export default Users
