import React, { useCallback } from 'react'

import { UseQueryResult, useQuery } from 'react-query'

import StyledChip from '../components/StyledChip'
import { ReactComponent as AnamneseIcon } from '../assets/icons/anamnese-icon.svg'
import BarChartIcon from '@mui/icons-material/BarChart'
import SecurityIcon from '@mui/icons-material/Security'
import EventIcon from '@mui/icons-material/Event'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import DateRangeIcon from '@mui/icons-material/DateRange'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import StarIcon from '@mui/icons-material/Star'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'

import RefreshButton from '../components/RefreshButton'

import { Application, Role, ApplicationPermission } from '../types'
import { fetchRoles } from '../api/API'
import { StyledTableCell } from '../components/EnchancedTable'
import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

const isNotMobile = (theme: Theme) => theme.breakpoints.up('sm')

const iconByApp = {
  [Application.INSIGHT]: <BarChartIcon />,
  [Application.HADES]: <SecurityIcon />,
  [Application.CALMASTER]: <EventIcon />,
  [Application.CORE]: <BusinessCenterIcon />,
  [Application.JARVIS]: <BusinessCenterIcon />,
  [Application.HAPPY]: <StarIcon />,
  [Application.ANA]: <AnamneseIcon />,
  [Application.SHIFT_PLANNER]: <DateRangeIcon />,
  [Application.TODO_LIST]: <FormatListBulletedIcon />,
  [Application.CLAIRE]: <LocalHospitalIcon />,
}

const useStyles = makeStyles((theme: Theme) => ({
  viewChip: {
    margin: theme.spacing(0.5),
  },
}))

const Applications = () => {
  const classes = useStyles()
  const roles: UseQueryResult<Role[]> = useQuery(['roles'], () => fetchRoles(0, 100, 'name'))

  const getViewsByApplication = useCallback((applications: ApplicationPermission[]) => {
    const viewsByApplication = applications.reduce<Record<string, string[]>>((applications, application) => {
      const viewsForThisApplication = applications[application.application] || []

      return {
        ...applications,
        [application.application]: viewsForThisApplication.includes(application.view)
          ? viewsForThisApplication
          : [...viewsForThisApplication, application.view],
      }
    }, {})

    return viewsByApplication
  }, [])

  const renderApplicationPermission = ([application, views]: [string, string[]]) => {
    return (
      <StyledChip
        className={classes.viewChip}
        key={application}
        label={
          <span>
            <strong>{application}: </strong>
            {views.join(', ')}
          </span>
        }
        icon={iconByApp[application as Application]}
        variant="outlined"
        color="primary"
      />
    )
  }

  const renderApplications = (applicationPermissions: ApplicationPermission[]) => {
    const viewsByApplication = getViewsByApplication(applicationPermissions)

    return Object.entries(viewsByApplication).map(renderApplicationPermission)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary" gutterBottom={false}>
          Application permissions
        </Typography>
      </Grid>

      <Grid item xs={6}>
        {useMediaQuery(isNotMobile) && (
          <Grid container justifyContent="flex-end">
            <RefreshButton loading={roles.isFetching} refresh={roles.refetch} />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {roles.error ? (
          <Alert severity="error">
            <>
              <AlertTitle>Error fetching Users</AlertTitle>
              {roles.error}
            </>
          </Alert>
        ) : (
          <Paper>
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Applications</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {roles.data?.map((role) => (
                    <TableRow>
                      <StyledTableCell>{role.name}</StyledTableCell>

                      <StyledTableCell>{renderApplications(role.applicationPermissions)}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

export default Applications
