import React, { useMemo } from 'react'

import { ApiKey } from '../../../types'
import { EnhancedTableHead, StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import moment from 'moment'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'

export interface CreateSystemUserCmd {
  name: string
}

interface Props {
  open: boolean
  apiKeys: ApiKey[]
  onCancel: () => void
  onToggleEnable: (apiKeyId: string, enabled: boolean) => void
}

const columns = [
  { key: 'key', label: 'Key', align: 'left', width: '45%' },
  { key: 'createdAt', label: 'Created At', align: 'left', width: '5%' },
  { key: 'createdBy', label: 'Created By', align: 'left', width: '35%' },
  { key: 'modifiedAt', label: 'Modified At', align: 'left', width: '5%' },
  { key: 'modifiedBy', label: 'Modified By', align: 'left', width: '35%' },
  { key: 'enabled', label: 'Enabled', align: 'center', width: '5%' },
]

const ApiKeyList: React.FC<Props> = ({ open, apiKeys, onCancel, onToggleEnable }) => {
  const cancel = () => {
    onCancel()
  }

  const rows = useMemo(
    () =>
      apiKeys.map((apiKey) => (
        <StyledTableRow hover role="checkbox" tabIndex={-1} key={apiKey.referenceId}>
          <StyledTableCell align="left"></StyledTableCell>
          <StyledTableCell align="left">{apiKey.key}</StyledTableCell>
          <StyledTableCell align="left">{moment(apiKey.createdAt).format('lll')}</StyledTableCell>
          <StyledTableCell align="left">{apiKey.createdBy}</StyledTableCell>
          <StyledTableCell align="left">{moment(apiKey.modifiedAt).format('lll')}</StyledTableCell>
          <StyledTableCell align="left">{apiKey.modifiedBy}</StyledTableCell>
          <StyledTableCell align="left">
            <Switch
              checked={apiKey.enabled}
              onChange={() => onToggleEnable(apiKey.referenceId, !apiKey.enabled)}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
          </StyledTableCell>
        </StyledTableRow>
      )),
    //no need to have dependency on onToggleEnable
    //eslint-disable-next-line
    [apiKeys]
  )

  return (
    <Dialog onClose={cancel} aria-labelledby="simple-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="simple-dialog-title">API Key</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <EnhancedTableHead
              columns={columns}
              rowCount={apiKeys.length}
              onRequestSort={() => {}}
              orderBy={'createdAt'}
              order={'asc'}
            />
            <TableBody>
              <colgroup>
                {columns.map((column) => (
                  <col key={`colgroup_${column.key}`} width={column.width} />
                ))}
              </colgroup>
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApiKeyList
