import React, { useState } from 'react'

import { List, ListItem, ListItemText, Popover, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'

import { Practitioner } from '../../../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
)

interface Props {
  practitioners?: Practitioner[]
}

const LinkedPractitioner: React.FC<Props> = ({ practitioners = [] }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      {practitioners.length > 0 ? (
        <CheckCircleOutlineIcon onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
      ) : (
        <BlockIcon />
      )}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List>
          {practitioners.map((practitioner) => (
            <ListItem key={practitioner.referenceId}>
              <ListItemText primary={practitioner.name} secondary={practitioner.clinic.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default LinkedPractitioner
