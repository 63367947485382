import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 88 88">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.212 41.212v-18.84C41.212 9.42 31.792 0 22.372 0H0v22.372c0 8.243 5.887 18.84 17.662 18.84h23.55zm4.849 0v-18.84C46.06 9.42 55.48 0 64.9 0h22.372v22.372c0 8.243-5.888 18.84-17.663 18.84H46.06zM0 66.667c0 11.38 9.226 20.606 20.606 20.606h9.158l11.448-11.448V46.06H20.606C9.226 46.06 0 55.286 0 66.667zm66.667 20.606c11.38 0 20.606-9.226 20.606-20.606S78.047 46.06 66.667 46.06H46.06V75.825l11.447 11.448h9.159z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M54.773 72.727a.58.58 0 01-.392-.153.58.58 0 01-.153-.392v-1.637c0-.436.182-.8.545-1.09l2.03-1.986c1.381-1.047 2.421-1.862 3.12-2.444.712-.581 1.25-1.105 1.614-1.57.378-.48.567-.931.567-1.353 0-1.077-.538-1.615-1.614-1.615-.568 0-1.004.175-1.31.524-.305.334-.508.756-.61 1.265-.087.335-.335.502-.742.502h-2.88a.522.522 0 01-.35-.13.495.495 0 01-.13-.328c.029-.96.29-1.825.785-2.596.51-.786 1.215-1.404 2.117-1.855.902-.465 1.942-.698 3.12-.698 1.221 0 2.276.196 3.163.589.888.393 1.557.945 2.008 1.658.465.713.698 1.542.698 2.487a4.512 4.512 0 01-.982 2.837c-.64.843-1.622 1.76-2.945 2.749L60.62 69.3h5.52c.16 0 .29.052.392.153a.532.532 0 01.153.393v2.335a.58.58 0 01-.153.392.532.532 0 01-.392.153H54.773zM73.695 72.727a.58.58 0 01-.392-.153.58.58 0 01-.153-.392V61.97l-2.924 2.247a.625.625 0 01-.327.11c-.175 0-.328-.088-.458-.263l-1.353-1.745a.649.649 0 01-.087-.327c0-.204.08-.364.24-.48l5.061-3.906a.853.853 0 01.502-.152h2.88c.16 0 .291.05.393.152a.498.498 0 01.174.393v14.182c0 .16-.058.29-.174.392a.531.531 0 01-.393.153h-2.989z"
        fill="#fff"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="43.636" y1="0" x2="81.212" y2="84.849" gradientUnits="userSpaceOnUse">
          <stop stopColor="#51BFB9" />
          <stop offset="1" stopColor="#24A19A" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default Logo
