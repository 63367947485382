import React from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { Order } from '../types'
import { IconButton, TableCell, TableHead, TableRow, Theme } from '@mui/material'
import { withStyles } from '@mui/styles'

interface EnhancedTableProps {
  columns: { key: string; label: string; width?: string }[]
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  leadColspan?: number
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
  columns,
  order,
  orderBy,
  onRequestSort,
  leadColspan = 1,
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" align="center" colSpan={leadColspan} />

        {columns.map((headCell) => (
          <StyledTableCell
            key={headCell.key}
            align={headCell.key === 'name' ? 'left' : 'right'}
            padding="normal"
            sortDirection={orderBy === headCell.key ? order : false}
          >
            {headCell.label}
            <IconButton
              onClick={createSortHandler(headCell.key)}
              size="small"
              color={orderBy === headCell.key ? 'primary' : 'default'}
              aria-label={`Sort by ${headCell.label}`}
            >
              {orderBy === headCell.key && order === 'desc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: 65,
  },
}))(TableRow)

const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    fontSize: '13px',
    padding: '16px 16px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: 'auto',
    whiteSpace: 'nowrap',
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
    '&:first-of-type': {
      top: 0,
      left: 0,
      zIndex: 3,
      position: 'sticky',
    },
  },
  body: {
    fontSize: '12px',
    '&:first-of-type': {
      left: '0px',
      position: 'sticky',
      zIndex: 1,
      background: 'inherit',
      '& span': {
        lineHeight: '24px',
      },
    },
  },
}))(TableCell)

export { StyledTableRow, EnhancedTableHead, StyledTableCell }
