import React from 'react'

import { makeStyles } from '@mui/styles'
import { Autocomplete, FormControl, TextField, Theme } from '@mui/material'
import { Tenant } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    '&.MuiFormControl-root': { minWidth: 160 },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectPaper: {
    // hack to fix Material UI popover position when disablePortal is true
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectList: {
    '& li:hover, li:focus': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))

interface Props {
  label?: string
  value: Tenant | null
  options: Tenant[]
  onChange: (val: Tenant | null) => void
  disabled?: boolean
  variant?: 'outlined' | 'filled' | 'standard'
}

const TenantPicker: React.FC<Props> = ({ label, value, options, onChange, disabled = false, variant = 'outlined' }) => {
  const classes = useStyles()

  const handleChange = (_: React.SyntheticEvent, value: Tenant | null) => {
    onChange(value)
  }

  return (
    <FormControl variant={variant} className={classes.formControl} fullWidth>
      <Autocomplete
        value={value}
        options={options}
        onChange={handleChange}
        getOptionLabel={(option: Tenant) => option.name}
        disabled={disabled}
        includeInputInList
        isOptionEqualToValue={(option, value) => value.referenceId === option.referenceId}
        clearIcon={null}
        renderInput={(params) => (
          <TextField {...params} margin={variant === 'outlined' ? 'normal' : 'dense'} variant={variant} label={label} />
        )}
      />
    </FormControl>
  )
}

export default TenantPicker
