import { Chip } from '@mui/material'
import { styled } from '@mui/styles'

const StyledChip = styled(Chip)(() => ({
  height: 23,
  fontSize: '0.75rem',

  '& .MuiChip-deleteIcon': {
    width: 15,
    height: 15,
  },

  '& .MuiChip-icon': {
    width: 15,
    height: 15,
  },
}))

export default StyledChip
