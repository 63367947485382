import { AxiosError } from 'axios'
import { APIError } from '../types'

export const getErrorMessages = (error: unknown): string | null => {
  return (error as AxiosError<APIError>)?.response?.data?.message || null
}

export const getHost = () => {
  const url = window.location.href
  const arr = url.split('/')
  return arr[0] + '//' + arr[2]
}

export const getErrorMessage = (error: any): string => {
  if (error.response) {
    let message = error.response.data.message
    if (error.response.data.fields) {
      error.response.data.fields.forEach((field: any) => {
        message += `\n ${field.field}: ${field.message}`
      })
    }
    return message
  } else {
    return error.message
  }
}
