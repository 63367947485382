import React from 'react'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Role } from '../types'
import { Autocomplete, Checkbox, FormControl, ListItem, TextField } from '@mui/material'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  label?: string
  value: Role[]
  options: Role[]
  onChange: (val: Role[]) => void
  disabled?: boolean
}

const RolesPicker: React.FC<Props> = ({ label, value, options, onChange, disabled = false }) => {
  const handleChange = (_: React.SyntheticEvent, value: Role[]) => {
    onChange(value)
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        multiple
        value={value}
        options={options}
        disableCloseOnSelect
        disableClearable
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={`option_${option.referenceId}`}>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </ListItem>
        )}
        disabled={disabled}
        includeInputInList
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      />
    </FormControl>
  )
}

export default RolesPicker
