import React, { useState, useMemo } from 'react'

import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  Popover,
  PopoverProps,
  Tooltip,
  Typography,
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InputIcon from '@mui/icons-material/Input'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import { useMutation } from 'react-query'

import { User } from '../../../types'

import * as api from '../../../api/API'
import { applicationsSupportingImpersonation, my21ImpersonationUrl, protocol } from '../../../config'
import { pluralize } from '../../../utils/StringUtils'
import { useCredentials } from '@dentalux/security'

interface Props {
  user: User
}

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const TokenGenerator: React.FC<Props> = ({ user }) => {
  const [token, setToken] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const credentials = useCredentials()

  const { mutate: generateToken, status } = useMutation(api.generateToken, {
    onSuccess: (token: string) => setToken(token),
  })

  const handleGenerateToken = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    if (credentials.authentication.referenceId === user.referenceId) {
      setToken(credentials.token)
    } else {
      generateToken(user.referenceId)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(token)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderApplicationItem = ({ name, url }: { name: string; url: string }) => {
    const urlWithToken = `${url}?jwt=${token}`

    return (
      <ListItemButton key={`${name}_${url}`} component="a" href={urlWithToken} target="blank">
        <ListItemText>{name}</ListItemText>

        <ListItemSecondaryAction>
          <ChevronRightIcon />
        </ListItemSecondaryAction>
      </ListItemButton>
    )
  }

  const renderMobileApplicationItem = (name: string, urlFn: (token: string) => string) => {
    const urlWithToken = urlFn(token)

    return (
      protocol && (
        <ListItemButton key={`${name}_mobile`} component="a" href={urlWithToken} target="blank">
          <ListItemText>{name}</ListItemText>

          <ListItemSecondaryAction>
            <ChevronRightIcon />
          </ListItemSecondaryAction>
        </ListItemButton>
      )
    )
  }

  const allowedApplicationsToImpersonate = useMemo(() => {
    return applicationsSupportingImpersonation.filter((application) =>
      application.haveAccess(user.roles.flatMap((roleForClinic) => roleForClinic.role.applicationPermissions))
    )
  }, [user.roles])

  return (
    <div>
      <Tooltip title="Generate Token">
        <IconButton size="small" onClick={handleGenerateToken}>
          {status === 'loading' ? <CircularProgress size={18} thickness={5} /> : <InputIcon />}
        </IconButton>
      </Tooltip>

      <Popover {...popoverProps} open={open && status !== 'loading'} anchorEl={anchorEl} onClose={handleClose}>
        <Grid
          container
          spacing={4}
          sx={{
            marginBottom: (theme) => theme.spacing(2),
            marginTop: (theme) => theme.spacing(2),
            paddingLeft: (theme) => theme.spacing(2),
            paddingRight: (theme) => theme.spacing(2),
            width: 500,
          }}
        >
          <Grid item xs={12}>
            <OutlinedInput
              disabled
              readOnly
              value={token}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton size="small" onClick={handleCopy}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              fullWidth
            />
          </Grid>

          {allowedApplicationsToImpersonate.length > 0 && (
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body2">
                You can use this token to impersonate users in{' '}
                {pluralize('this', 'these', allowedApplicationsToImpersonate.length)} applications:
              </Typography>

              <List dense={true} sx={{ marginBottom: 0 }}>
                {allowedApplicationsToImpersonate.map(renderApplicationItem)}
                {renderMobileApplicationItem('My21', my21ImpersonationUrl)}
              </List>
            </Grid>
          )}
        </Grid>
      </Popover>
    </div>
  )
}

export default TokenGenerator
