import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import Main from './Main'
import { QueryClient, QueryClientProvider } from 'react-query'

import { SecurityProvider, LoginPage, ProtectedRoute } from '@dentalux/security'
import { Application } from './types'
import { createTheme, ThemeProvider } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      light: '#7e8c97',
      main: '#24A1A1',
      dark: '#10536C',
      contrastText: '#fff',
    },
    secondary: {
      light: '#556069',
      main: '#F6FBFB',
      dark: '#c8e3e3',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#000',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#E8F6FB !important',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#20a8d8',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
  },
})

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
      mutations: {
        // mutation options
      },
    },
  })

  const getSecurityEnv = (): 'staging' | 'production' => {
    if (window.location.host.startsWith('localhost') || window.location.host.startsWith('staging')) {
      return 'staging'
    }

    return 'production'
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SecurityProvider
            config={{
              loginPath: '/login',
              successPath: '/',
              loading: 'Loading...',
              application: Application.HADES,
              env: getSecurityEnv(),
              defaultLanguage: 'en',
              endpoint: process.env.REACT_APP_HADES_URI,
            }}
          >
            <Switch>
              <Route path="/login" exact>
                <LoginPage title={Application.HADES} />
              </Route>

              <ProtectedRoute path="/">
                <Main />
              </ProtectedRoute>
            </Switch>
          </SecurityProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
