import React, { useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ListIcon from '@mui/icons-material/List'
import AddIcon from '@mui/icons-material/Add'

import { StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import { ApiKey, Clinic, Role, SystemUser, Tenant } from '../../../types'
import RolePicker from '../../../components/RolePicker'
import ClinicPicker from '../../../components/ClinicPicker'
import { createStyles, makeStyles } from '@mui/styles'
import { CircularProgress, IconButton, Theme } from '@mui/material'
import TenantPicker from '../../../components/TenantPicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.4),
      marginBottom: theme.spacing(0.7),
      marginTop: theme.spacing(0.7),
    },
  })
)

interface Props {
  user: SystemUser
  roles: Role[]
  tenants: Tenant[]
  clinics: Clinic[]
  saveEdit: (userId: string, roleId: string | null, tenantId: string | null, clinics: string[]) => void
  createApiKey: (userId: string) => void
  showApiKeys: (apiKeys: ApiKey[]) => void
  loading: boolean
}

const SystemUserRow: React.FC<Props> = ({
  user,
  roles,
  tenants,
  clinics,
  saveEdit,
  loading,
  createApiKey,
  showApiKeys,
}) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [role, setRole] = useState(user.role)
  const [tenant, setTenant] = useState(user.tenant)
  const [userClinics, setUserClinics] = useState(user.clinics)

  const toggleEdit = () => {
    setEdit(!edit)
  }

  const onSaveRole = (roleId: string) => {
    const newRole = roles.filter((role) => role.referenceId === roleId)[0]
    setRole(newRole)
  }

  const onSave = () => {
    saveEdit(
      user.referenceId,
      role?.referenceId || null,
      tenant?.referenceId || null,
      userClinics.map((clinic) => clinic.referenceId)
    )
    toggleEdit()
  }

  return edit ? (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={user.referenceId}>
      <StyledTableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={onSave} color="primary">
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <CheckIcon />}
        </IconButton>
        <IconButton size="small" onClick={toggleEdit}>
          <CloseIcon />
        </IconButton>
      </StyledTableCell>

      <StyledTableCell align="left">{user.name}</StyledTableCell>

      <StyledTableCell align="right">
        <RolePicker value={role} options={roles} onChange={onSaveRole} />
      </StyledTableCell>

      <StyledTableCell align="right">
        {tenants.length ? <TenantPicker value={tenant} options={tenants} onChange={setTenant} /> : user.tenant?.name}
      </StyledTableCell>

      <StyledTableCell align="right">
        <ClinicPicker value={userClinics} options={clinics} onChange={setUserClinics} />
      </StyledTableCell>

      <StyledTableCell align="right">{user.apiKeys.length}</StyledTableCell>
    </StyledTableRow>
  ) : (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={user.referenceId}>
      <StyledTableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={toggleEdit}>
          {loading ? <CircularProgress size={24} className={classes.icon} /> : <EditIcon />}
        </IconButton>
      </StyledTableCell>

      <StyledTableCell align="left">{user.name}</StyledTableCell>

      <StyledTableCell align="right">{user.role?.name}</StyledTableCell>

      <StyledTableCell align="right">{user.tenant?.name}</StyledTableCell>

      <StyledTableCell align="right">{user.clinics.map((clinic) => clinic.name).join(', ')}</StyledTableCell>

      <StyledTableCell align="right">
        {user.apiKeys.length}{' '}
        <IconButton onClick={() => showApiKeys(user.apiKeys)}>
          <ListIcon />
        </IconButton>
        <IconButton onClick={() => createApiKey(user.referenceId)}>
          <AddIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default SystemUserRow
