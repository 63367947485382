import React from 'react'

import SyncIcon from '@mui/icons-material/Sync'
import { createStyles, makeStyles } from '@mui/styles'
import { Button, CircularProgress, Theme } from '@mui/material'

interface Props {
  loading: boolean
  refresh: () => any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.4),
      marginBottom: theme.spacing(0.7),
      marginTop: theme.spacing(0.7),
    },
  })
)

const RefreshButton: React.FC<Props> = ({ loading, refresh }) => {
  const classes = useStyles()

  return (
    <Button color="primary" size="large" onClick={refresh}>
      {loading ? <CircularProgress size={24} className={classes.icon} /> : <SyncIcon fontSize="large" />}
      {'REFRESH'}
    </Button>
  )
}

export default RefreshButton
