import React from 'react'

import { Role } from '../types'
import { useCredentials } from '@dentalux/security'
import { makeStyles } from '@mui/styles'
import { FormControl, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectPaper: {
    // hack to fix Material UI popover position when disablePortal is true
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectList: {
    '& li:hover, li:focus': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))

interface Props {
  label?: string
  value: Role | null
  options: Role[]
  onChange: (roleId: string) => void
  disabled?: boolean
}

const RolePicker: React.FC<Props> = ({ value, options, onChange, disabled = false }) => {
  const { authentication: user } = useCredentials()
  const classes = useStyles()

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        value={value?.referenceId || ''}
        disabled={disabled}
        onChange={handleChange}
        inputProps={{
          name: 'Timegroup',
          id: 'outlined-age-native-simple',
        }}
        MenuProps={{
          classes: { paper: classes.selectPaper, list: classes.selectList },
          disablePortal: false,
          disableScrollLock: true,
        }}
      >
        {options
          .filter((option) => option.name !== 'HQ_SUPER_ADMIN' || user?.role?.name === 'HQ_SUPER_ADMIN')
          .map((option) => (
            <MenuItem key={`option_${option.referenceId}`} value={option.referenceId}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default RolePicker
