export const simplifyName = (name: string): string => {
  if (name.length > 17) {
    const splittedValue = name.split(' ')
    return splittedValue.length > 1 ? splittedValue.slice(1).join(' ') : name
  }
  return name
}

export const pluralize = (singular: string, plural: string, quantity: number): string =>
  quantity > 1 ? plural : singular
