import React, { MouseEventHandler, useState } from 'react'

import Logo from './Logo'
import { useCredentials, useLogout } from '@dentalux/security/lib'
import { createStyles, makeStyles } from '@mui/styles'
import { AppBar, Avatar, IconButton, Menu, MenuItem, Theme, Toolbar, Typography } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      marginRight: theme.spacing(1),
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    grow: {
      flexGrow: 1,
    },
  })
)

interface Props {
  handleMenuClose: MouseEventHandler
  navItems?: React.ReactNode
}

const NavBar: React.FC<Props> = ({ handleMenuClose, navItems }) => {
  const classes = useStyles()
  const { authentication: user } = useCredentials()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const logout = useLogout()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.common.black,
      }}
      elevation={1}
    >
      <Toolbar>
        <IconButton
          sx={{ marginLeft: '-4px', marginRight: '24px' }}
          color="inherit"
          aria-label="open drawer"
          onClick={handleMenuClose}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Logo className={classes.logo} />
        <Typography variant="h6">Hades</Typography>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>{navItems}</div>
        {user && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt={user.name} src={user.imgUrl as string} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
