import { RoleForClinics } from '../types'

export const canGenerateTokens = (rolesForClinic: RoleForClinics[]) => {
  return (
    rolesForClinic
      .map((roleForClinic) => roleForClinic.role)
      .flatMap((role) => role.applicationPermissions)
      .find((permission) => permission.application === 'HADES' && permission.view === 'USER_IMPERSONATE') || false
  )
}
