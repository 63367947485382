import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../components/EnchancedTable'
import { Revision, User, RevisionType } from '../../../types'
import moment from 'moment'
import { createStyles, makeStyles } from '@mui/styles'
import { List, ListItem } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    changeItem: {
      alignItems: 'baseline',
    },
    changeValue: {
      margin: 0,
      marginLeft: '1ex',
    },
  })
)

const revisionTypeToText: { [key in RevisionType]: string } = {
  ADD: 'Created',
  MOD: 'Modified',
  DEL: 'Deleted',
}

interface ModificationProps {
  changes: string[]
  state: User
}

const Modification: React.FC<ModificationProps> = ({ changes, state }) => {
  const classes = useStyles()

  return (
    <List>
      {changes.map((change) => (
        <ListItem className={classes.changeItem}>
          Set {change} to{' '}
          <pre className={classes.changeValue}>{JSON.stringify(state[change as keyof User], null, 2)}</pre>
        </ListItem>
      ))}
    </List>
  )
}

interface Props {
  state: User
  revision: Revision
}

const UserChangeRow: React.FC<Props> = ({ state, revision }) => {
  return (
    <StyledTableRow hover role="checkbox" tabIndex={-1} key={revision.rev}>
      <StyledTableCell>{moment(revision.createdAt).format('YYYY-MM-DD HH:mm:ss')}</StyledTableCell>

      <StyledTableCell>{revision.createdBy}</StyledTableCell>
      <StyledTableCell>
        {state.name} ({state.email})
      </StyledTableCell>

      <StyledTableCell>
        {revisionTypeToText[revision.type]}{' '}
        {revision.type === RevisionType.MOD && <Modification changes={revision.changes} state={state} />}
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default UserChangeRow
