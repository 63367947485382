import React from 'react'

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'

import { ApiKeyFull } from '../../../types'

export interface CreateSystemUserCmd {
  name: string
}

interface Props {
  open: boolean
  apiKey: ApiKeyFull | null
  error: string | null
  onCancel: () => void
}

const ApiKeyCreateResult: React.FC<Props> = ({ open, apiKey, error, onCancel }) => {
  const cancel = () => {
    onCancel()
  }

  return (
    <Dialog onClose={cancel} aria-labelledby="simple-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="simple-dialog-title">API Key</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error creating new user</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        {apiKey && (
          <div>
            <DialogContentText>Please copy the secret value now! This will not be shown again.</DialogContentText>
            <Grid container component="dl" spacing={2}>
              <Grid item>
                <Typography component="dt" variant="h6">
                  Key
                </Typography>
                <Typography component="dd" variant="body2">
                  {apiKey.key}
                </Typography>
                <Typography component="dt" variant="h6">
                  Secret
                </Typography>
                <Typography component="dd" variant="body2" paragraph={true}>
                  {apiKey.secret}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApiKeyCreateResult
