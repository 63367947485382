export const groupBy = (collection: Array<{ [key: string]: any }>, key: string) =>
  collection.reduce((acc, element) => {
    ;(acc[element[key]] = acc[element[key]] || []).push(element)
    return acc
  }, {})

export const groupByNested = (
  collection: Array<{ [key: string]: { [key2: string]: any } }>,
  key: string,
  key2: string
) =>
  collection.reduce((acc, element) => {
    ;(acc[element[key][key2]] = acc[element[key][key2]] || []).push(element)
    return acc
  }, {})

export const containsSame = <T>(arrA: T[], arrB: T[]): boolean => {
  if (arrA.length !== arrB.length) {
    return false
  }

  const sortedA = arrA.concat().sort()
  const sortedB = arrB.concat().sort()

  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false
    }
  }

  return true
}
