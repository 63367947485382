import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import React, { useState, ChangeEvent } from 'react'

import { AuthenticationType, Tenant } from '../../../types'
import TenantPicker from '../../../components/TenantPicker'

export interface CreateUserCmd {
  name: string
  email: string
  password: string
  authenticationType: string
  tenant: Tenant | null
}

interface Props {
  open: boolean
  loading: boolean
  inTenant: Tenant | null
  tenants: Tenant[]
  error: string | null
  onCancel: () => void
  onCreate: (cmd: CreateUserCmd) => void
}

const UserCreator: React.FC<Props> = ({ open, loading, inTenant, tenants, error, onCancel, onCreate }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [authenticationType, setAuthenticationType] = useState(AuthenticationType.PASSWORD.toString())
  const [password, setPassword] = useState('')
  const [tenant, setTenant] = useState<Tenant | null>(inTenant)

  const cancel = () => {
    onCancel()
  }

  const submit = () => {
    onCreate({
      name,
      email,
      authenticationType,
      password,
      tenant,
    })
  }

  const onChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value)
  }

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value)
  }

  const onChangeAuthenticationType = (event: SelectChangeEvent<string>) => {
    setAuthenticationType(event.target.value)
  }

  const onChangePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  return (
    <Dialog onClose={cancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Create new user</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the required data in order to create a new user.</DialogContentText>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error creating new user</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        <TextField
          value={name}
          onChange={onChangeName}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={email}
          onChange={onChangeEmail}
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl margin="dense" fullWidth>
          <InputLabel id="authentication-type-label">Authenticate with</InputLabel>
          <Select
            id="authenticationType"
            value={authenticationType}
            onChange={onChangeAuthenticationType}
            labelId="authentication-type-label"
            disabled={loading}
          >
            {Object.entries(AuthenticationType).map(([key, value]) => (
              <MenuItem key={value} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={password}
          onChange={onChangePassword}
          margin="dense"
          id="password"
          label="Password"
          type="password"
          disabled={authenticationType !== AuthenticationType.PASSWORD || loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TenantPicker value={tenant} options={tenants} onChange={setTenant} variant="standard" label="Tenant" />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          {!loading ? 'Create' : <CircularProgress color="primary" size="1.2em" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserCreator
