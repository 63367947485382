import React, { useState } from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'

import { Clinic } from '../types'
import { Autocomplete, Checkbox, FormControl, ListItem, TextField } from '@mui/material'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  label?: string
  value: Clinic[]
  options: Clinic[]
  onChange: (val: Clinic[]) => void
  disabled?: boolean
}

const ClinicPicker: React.FC<Props> = ({ label, value, options, onChange, disabled = false }) => {
  const [values, setValues] = useState(value)
  const [open, setOpen] = useState(false)
  const handleChange = (event: React.SyntheticEvent, value: Clinic[], reason: string) => {
    setValues(value)
    if (reason === 'removeOption' && !open) {
      onChange(value)
    }
  }

  const handleOnOpen = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    onChange(values)
    setOpen(false)
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        multiple
        value={values}
        options={options}
        disableClearable
        disableCloseOnSelect
        onChange={handleChange}
        onClose={handleOnClose}
        onOpen={handleOnOpen}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={`option_${option.referenceId}`}>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </ListItem>
        )}
        isOptionEqualToValue={(option, value) => value.referenceId === option.referenceId}
        disabled={disabled}
        includeInputInList
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      />
    </FormControl>
  )
}

export default ClinicPicker
