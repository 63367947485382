import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { useState, ChangeEvent } from 'react'
import { Tenant } from '../../../types'
import TenantPicker from '../../../components/TenantPicker'

export interface CreateSystemUserCmd {
  name: string
  tenantReferenceId: string
}

interface Props {
  open: boolean
  loading: boolean
  inTenant: Tenant | null
  tenants: Tenant[]
  error: string | null
  onCancel: () => void
  onCreate: (cmd: CreateSystemUserCmd) => void
}

const SystemUserCreator: React.FC<Props> = ({ open, loading, inTenant, tenants, error, onCancel, onCreate }) => {
  const [name, setName] = useState('')
  const [tenant, setTenant] = useState<Tenant | null>(inTenant)

  const cancel = () => {
    onCancel()
  }

  const submit = () => {
    if (tenant != null) {
      onCreate({
        name,
        tenantReferenceId: tenant.referenceId,
      })
    }
  }

  const onChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value)
  }

  return (
    <Dialog onClose={cancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Create new user</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the required data in order to create a new user.</DialogContentText>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error creating new user</AlertTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{error}</p>
          </Alert>
        )}
        <TextField
          value={name}
          onChange={onChangeName}
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          disabled={loading}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TenantPicker value={tenant} options={tenants} onChange={setTenant} variant="standard" label="Tenant" />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={loading}>
          {!loading ? 'Create' : <CircularProgress color="primary" size="1.2em" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SystemUserCreator
